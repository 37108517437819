import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WhatsappTemplateSettingModel } from '../model/whatsapp-template-setting.model';
import { BaseService } from '../../../../shared/base-service';

@Injectable({ providedIn: 'root' })
export class WhatsappTemplateSettingService extends BaseService<WhatsappTemplateSettingModel> {
    constructor(httpClient: HttpClient) {
        super( httpClient, 'whatsapp_templates');
    }
}
